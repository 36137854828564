body{
    font-family: Poppins,sans-serif;
}
.bg-alternate-section {
    background-color: var(--bs-gray-100);
}
.container-section1 .heading {
    padding: 8rem 3rem 0rem 3rem;
    font-size: 3rem;
    font-weight: 600;
}
.container-section1 .message {
    font-size: 1.1rem;
    padding: 0 0px 0 3rem;
}
.container-section1 .try-button {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
}
.container-section1 .intro-video {
    padding: 0.25rem;
    background-color: #fff;
    max-width: 100%;
    height: auto;
    width: 80%;
}
.course-image {
    width: 100%;
    position: relative;
}
.course-image img {
    width: 100%;
}
.course-watermarks{
    position: absolute;
}
.course-watermarks.most-rated-title {
    color: yellow;
    top: 0;
    left: 0;
    padding: 5px;
    border-radius: 5px;
    background-color: rgba(0,0,0,0.2);
    margin: 2px;
}
.course-watermarks.ref-links {
    bottom: 0;
    right: 0;
    color: white;
}
.course-watermarks.ref-links button {
    all: unset;
    margin-right: 5px;
    cursor: pointer;
    padding: 2px 5px 2px 5px;
    border-radius: 5px;
    background-color: rgba(0,0,0,0.2);
    margin-bottom: 2px;
    font-size: 1.2rem;
}
span.most-viewed{
    padding-left: 5px;
}
.course-stats{
        color: rgba(0,0,0,0.4);
        font-size: 0.95rem;
        margin-bottom: 10px;
}
.how-it-works-container {
    position: relative;
}
.how-it-works-container .container-header {
    position: absolute;
    top: 3rem;
}
.how-it-works-container img {
    width: 100%;
}
.how-it-works-container button {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    bottom: 3rem;
    padding-left: 3rem;
    padding-right: 3rem;
}
.web-subscription-section {
    width: 100%;
    background-color: #f3f0f8;
    height: 400px;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
    padding: 30px;
}
.web-subscription-section img {
    width: 12rem;
    filter: drop-shadow(1px 1px 1px #8a5dd2);
}
.app-footer-container a,.app-footer-container .btn {
    text-decoration: none;
    color: darkgray;
    font-weight: 500;
}
.app-footer-container .active {
    color: black;
}
.app-footer-container a:focus,.app-footer-container .btn:focus{
    outline: 0;
    box-shadow: none;
}